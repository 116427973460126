body {
  @apply bg-white m-0 p-0 pb-16
}

main {
  @apply w-full font-futura mx-auto;
}

* {
  box-sizing: border-box;
}
