footer {
  @apply bg-yellow pt-8;

  img {
    @apply block mx-auto -mb-64;
    max-width: 80%;
    animation-name: creativity;
    animation-play-state: paused;
    animation-fill-mode: both;
    animation-duration: .5s;

    /* purgecss ignore */
    &.inView {
      animation-play-state: running;
    }
  }

  .content {
    @apply bg-white;
  }

  .inner {
    @apply mx-auto w-full max-w-5xl px-2;
    opacity: 0;
    transform: translateX(-5rem);
    transition: all .75s ease;

    /* purgecss ignore */
    &.inView {
      opacity: 1;
      transform: translateX(0);
    }
  }

  h2 {
    @apply font-futuraBold text-4xl uppercase leading-none mt-16 pt-32 pb-0 mb-0;
  }

  p {
    @apply text-xl;
  }

  a {
    @apply text-teal;
  }
}

@media (min-width: 48rem) {
  footer {
    img {
      @apply -mb-128;
    }
    h2 {
      @apply text-7xl;
    }
    p {
      @apply text-2xl pb-32;
    }
  }
}

@keyframes creativity {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  20% {
    opacity: 1;
  }
  80% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
