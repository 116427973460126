.rows {
  @apply mt-4 max-w-10xl mx-auto;
}

.row {
  @apply flex flex-wrap pt-4 items-center;
  opacity: 0;
  transform: translateY(5rem);
  transition: all .75s ease;

  &:nth-child(even) {
    @apply flex-row-reverse;
  }

  /* purgecss ignore */
  &.inView {
    opacity: 1;
    transform: translateY(0);
  }

  .video,
  .text {
    @apply w-full;
  }

  .text {
    @apply p-8;

    h2 {
      @apply font-futura font-medium text-4xl text-grey mt-0 pt-0;
    }

    p {
      @apply font-futura font-normal text-xl text-grey;
    }
  }

  .video {
    @apply relative overflow-hidden;

    .inner {
      @apply relative w-full h-0;
      padding-top: 56.25%;
    }

    iframe {
      @apply block absolute w-full h-full top-0 right-0 bottom-0 left-0;
    }
  }
}

@media (min-width: 48rem) {
  .row {
    .video,
    .text {
      @apply w-1/2;
    }
    .text { @apply p-16; }
  }
}
