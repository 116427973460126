.hero {
  @apply relative w-full max-w-7xl mx-auto p-0 overflow-hidden;

  .inner {
    @apply relative w-full h-0;
    padding-top: 56.25%;
  }

  iframe {
    @apply block absolute w-full h-full top-0 right-0 bottom-0 left-0;
  }
}
