header {
  @apply w-full;

  h1 {
    @apply m-0 py-10 px-4;
  }

  img {
    @apply block w-full max-w-2xl mx-auto;
  }
}
